import { Drawer, Form, Input, DatePicker, Button, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const CampaignsDrawer = ({
  open,
  onClose,
  onSave,
  editable,
  clientsOptions,
  filterOptions,
  pricingOptions,
  loading,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const body = {
      ...values,
      start_date: values.start_date.format("YYYY-MM-DD"),
      end_date: values.end_date.format("YYYY-MM-DD"),
    };

    onSave(body);
  };

  useEffect(() => {
    if (editable) {
      const editableDates = {
        ...editable,
        start_date: dayjs(editable.start_date),
        end_date: dayjs(editable.end_date),
      };
      form.setFieldsValue(editableDates);
    }
  }, [editable]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <Drawer
      title={editable ? t("edit_campaign") : t("add_campaign")}
      open={open}
      width={620}
      destroyOnClose
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {t("cancel")}
          </Button>
          <Button type="primary" onClick={() => form.submit()} loading={loading}>
            {t("save")}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("max_budget")}
          name="max_budget"
          rules={[
            { required: true, message: t("max_budget_required") },
            {
              pattern: new RegExp(/^[0-9]+$/),
              message: t("max_budget_number"),
            },
          ]}
        >
          <Input placeholder={t("enter_max_budget")} />
        </Form.Item>

        <Form.Item
          label={t("title")}
          name="title"
          rules={[{ required: true, message: t("title_required") }]}
        >
          <Input placeholder={t("enter_title")} />
        </Form.Item>

        <Form.Item
          label={t("client_id")}
          name="client_id"
          rules={[{ required: true, message: t("client_id_message") }]}
        >
          <Select
            options={clientsOptions}
            placeholder={t("client_id_placeholder")}
            showSearch
          />
        </Form.Item>

        <Form.Item
          label={t("start_date")}
          name="start_date"
          rules={[{ required: true, message: t("start_date_required") }]}
        >
          <DatePicker style={{ width: "100%" }} placeholder={t("select_start_date")} />
        </Form.Item>

        <Form.Item
          label={t("end_date")}
          name="end_date"
          rules={[{ required: true, message: t("end_date_required") }]}
        >
          <DatePicker style={{ width: "100%" }} placeholder={t("select_end_date")} />
        </Form.Item>

        <Form.Item
          label={t("filter_id")}
          name="filter_id"
          rules={[{ required: true, message: t("filter_id_required") }]}
        >
          <Select
            options={filterOptions}
            placeholder={t("select_filter_id")}
            showSearch
          />
        </Form.Item>

        <Form.Item
          label={t("pricing_package_id")}
          name="pricing_package_id"
          rules={[{ required: true, message: t("pricing_package_id_required") }]}
        >
          <Select
            options={pricingOptions}
            placeholder={t("select_pricing_package_id")}
            showSearch
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CampaignsDrawer;
