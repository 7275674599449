import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableComponent from "../../../../_components/table/TableComponent";
// import { getColumnSearchProps } from "../../../../config/config";
import { getColumnSearchProps } from "../../../../config/config";
import { getAtSession, setInSession } from "../../../../helpers/storages";
import { Typography } from "antd";
import TableImage from "../../../../_components/table/TableImage";
import icons from "../../../../config/icons";
import TableButtons from "../../../../_components/table/TableButtons";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import { asc_desc } from "../../../../helpers/ascDesc";

const RadioTable = ({
  getDataSource,
  handleMenuClick,
  setOpenDrawer,
  getAgain,
  loading,
}) => {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [currentPage, setCurrentPage] = useState(getAtSession("music_page")?.page || 1);
  const [limit, setLimit] = useState(getAtSession("music_page")?.limit || 10);
  const [total, setTotal] = useState(5);

  const [sort, setSort] = useState(getAtSession("music_page")?.sort || ["id", "DESC"]);
  const [search, setSearch] = useState(
    getAtSession("music_page")?.search || {
      name: "",
      description: "",
      singer: "",
    }
  );

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      fixed: "left",
      width: 50,
      sorter: true,
      defaultSortOrder:
        getAtSession("music_page")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("music_page")?.sort?.[1]),
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      defaultFilteredValue: getAtSession("music_page")?.search?.name,
      filteredValue: getAtSession("music_page")?.search?.name,
      ...getColumnSearchProps(),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      align: "center",
      defaultFilteredValue: getAtSession("music_page")?.search?.description,
      filteredValue: getAtSession("music_page")?.search?.description,
      ...getColumnSearchProps(),
    },
    {
      title: t("image"),
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record) => {
        return <TableImage src={record?.image} />;
      },
    },
    {
      title: t("url"),
      dataIndex: "url",
      key: "url",
      align: "center",
      // eleipsis: true,
      ellipsis: true,
      // defaultFilteredValue: getAtSession("music_page")?.search?.url,
      // filteredValue: getAtSession("music_page")?.search?.url,
      render: (url) => (
        <Typography.Paragraph
          ellipsis={true}
          style={{ margin: 0 }}
          copyable={{
            tooltips: false,
          }}
        >
          {url}
        </Typography.Paragraph>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 70,
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={[
              {
                key: "edit",
                name: t("edit"),
                icon: icons.EDIT,
              },
              {
                key: "delete",
                name: t("delete"),
                question: t("delete_music", { name: record.name }),
                icon: icons.DELETE,
                onConfirm: () => {
                  handleMenuClick({ key: "delete" }, record);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    const music_categories = {
      page: data.page,
      sort: data.sort,
      search: data.search,
    };

    setInSession("music_categories", music_categories);

    setCurrentPage(data.page);
    setLimit(data.pageSize);
    setSort(data.sort);
    setSearch(data.search);
  };

  useEffect(() => {
    const query = {
      page: currentPage,
    };

    if (sort[1]) {
      query.sort = sort;
    }

    if (search.name) {
      query.search = {
        name: search.name,
      };
    }

    if (search.description) {
      query.search = {
        description: search.description,
      };
    }

    if (search.singer) {
      query.search = {
        singer: search.singer,
      };
    }

    const success = (response) => {
      setDataSource(response?.rows);
      setTotal(response?.total);
      setCurrentPage(response?.page);
    };

    const error = (err) => {
      console.log(err);
    };

    getDataSource(query, success, error);
  }, [currentPage, limit, sort, search, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div>
            <ButtonComponent
              title={t("add_radio")}
              icon={icons.ADD}
              onClick={() => setOpenDrawer(true)}
            />
          </div>
        }
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        isLoading={loading}
        pagination={{
          current: currentPage,
          pageSize: limit,
          total: total,
        }}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};

export default RadioTable;
