import styles from "../style.module.scss";
import { Form } from "antd";
import { AdsContext } from "../AdsContentPage";
import { useContext } from "react";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";
import icons from "../../../../config/icons";

export const AdsForm = ({ children, form }) => {
  const { onSave, setPreviewData, previewData } = useContext(AdsContext);
  const { t } = useTranslation();

  const handleFinish = (values) => {
    onSave(values);
  };

  const handleCHange = ({ target }) => {
    if (target.id == "title") {
      if (target.value.length < 1) {
        setPreviewData({
          ...previewData,
          title: "Title",
        });
      } else {
        setPreviewData({
          ...previewData,
          title: target.value,
        });
      }
    } else if (target.id == "description") {
      if (target.value.length < 1) {
        setPreviewData({
          ...previewData,
          description: "Description",
        });
      } else {
        setPreviewData({
          ...previewData,
          description: target.value,
        });
      }
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        className={styles["ads-page-form"]}
        onChange={handleCHange}
      >
        {children}
      </Form>
      <div className={styles["ads-button"]}>
        <ButtonComponent
          title={t("save")}
          icon={icons.CHECK}
          onClick={() => form.submit()}
        />
      </div>
    </>
  );
};

export default AdsForm;
