import { useEffect } from "react";
import { Drawer } from "antd";
import TerminalComponent from "../TerminalComponent";

const SshDrawer = ({ open, onClose, socket }) => {
  const handleFullScreen = () => {
    const element = document.querySelector("#terminal");
    if (element) {
      element.requestFullscreen();
    }
  };

  useEffect(() => {
    if (open) {
      socket?.emit("shell_connect");
    }

    return () => {
      if (socket) {
        socket.emit("shell_disconnect");
      }
    };
  }, [open, socket]);

  return (
    <Drawer
      title="Terminal"
      placement="bottom"
      zIndex={100000}
      extra={
        <div
          onClick={handleFullScreen}
          style={{
            cursor: "pointer",
            marginRight: "10px",
          }}
        >
          <i className="fas fa-expand" />
        </div>
      }
      height={600}
      open={open}
      onClose={onClose}
      destroyOnClose
    >
      {open && <TerminalComponent socket={socket} />}
    </Drawer>
  );
};

export default SshDrawer;
