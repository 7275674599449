import { useRef, useLayoutEffect, useEffect } from "react";
import { Terminal } from "@xterm/xterm";
import { FitAddon } from "@xterm/addon-fit";

const TerminalComponent = ({ socket }) => {
  const terminalRef = useRef(null);
  const term = useRef(new Terminal({ cursorBlink: true }));
  const fitAddon = useRef(new FitAddon());

  useEffect(() => {
    term.current = new Terminal({ cursorBlink: true });
    fitAddon.current = new FitAddon();
    const terminal = term.current;

    terminal.loadAddon(fitAddon.current);
    terminal.open(terminalRef.current);
    fitAddon.current.fit();

    const resizeTerminal = () => {
      if (socket) {
        socket.emit("shell_resize", {
          cols: terminal.cols,
          rows: terminal.rows,
        });
      }
      fitAddon.current.fit();
    };

    const handleShellOutput = (data) => {
      terminal.write(data);
    };

    socket?.on("shell_output", handleShellOutput);

    terminal.onData((data) => {
      socket?.emit("shell_input", data);
    });

    resizeTerminal();
    window.addEventListener("resize", resizeTerminal);

    return () => {
      window.removeEventListener("resize", resizeTerminal);
      socket?.off("shell_output", handleShellOutput);
      if (terminal) {
        terminal.dispose();
      }
      if (fitAddon.current) {
        fitAddon.current.dispose();
      }
    };
  }, [socket]);

  return (
    <div
      id="terminal"
      ref={terminalRef}
      style={{
        height: "500px",
        // height: "100%",
        width: "100%",
        backgroundColor: "#1e1e1e",
        // backgroundColor: "red",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    ></div>
  );
};

export default TerminalComponent;
