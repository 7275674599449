import { useEffect, useState } from "react";
import { Tag } from "antd";
import TableComponent from "../../../_components/table/TableComponent";
import Check from "../../../_components/table/Check";
import TableButtons from "../../../_components/table/TableButtons";
import { useTranslation } from "react-i18next";
import icons from "../../../config/icons";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import { getColumnSearchProps } from "../../../config/config";

const ResellerTable = ({
  dataSource,
  loading,
  getAgain,
  handleMenuClick,
  addUser,
  countryOptions,
  bulkAction,
}) => {
  const { t } = useTranslation();

  const statusOptions = [
    { text: t("active"), value: true },
    { text: t("inactive"), value: false },
  ];

  const archiveOptions = [
    { text: t("archived"), value: false },
    { text: t("not_archived"), value: true },
  ];

  const [dataSources, setDataSources] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_reseller_page") || 10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("resellers_page")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("resellers_page")?.sort || ["id", "DESC"]
  );

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("resellers_page")?.search || {
      full_name: null,
      email: null,
      phone: null,
      address: null,
      country: null,
      status: null,
      archived: false,
    }
  );

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 50,
      align: "center",
      fixed: "left",
      sorter: true,
      defaultSortOrder:
        getAtSession("resellers_page")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("resellers_page")?.sort?.[1]),
    },
    {
      title: t("full_name"),
      dataIndex: "full_name",
      key: "full_name",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("resellers_page")?.search?.full_name,
      filteredValue: getAtSession("resellers_page")?.search?.full_name,
      ...getColumnSearchProps(),
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("resellers_page")?.search?.email,
      filteredValue: getAtSession("resellers_page")?.search?.email,
      ...getColumnSearchProps(),
    },
    {
      title: t("phone"),
      dataIndex: "phone",
      key: "phone",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("resellers_page")?.search?.phone,
      filteredValue: getAtSession("resellers_page")?.search?.phone,
      ...getColumnSearchProps(),
    },
    {
      title: t("address"),
      dataIndex: "address",
      key: "address",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("resellers_page")?.search?.address,
      filteredValue: getAtSession("resellers_page")?.search?.address,
      ...getColumnSearchProps(),
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      ellipsis: true,
      filters: countryOptions,
      filterSearch: true,

      defaultFilteredValue: getAtSession("resellers_page")?.search?.country,
      filteredValue: getAtSession("resellers_page")?.search?.country,
      search: true,
      onFilter: (value, record) => true,
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      ellipsis: true,
      filters: statusOptions,
      defaultFilteredValue: getAtSession("resellers_page")?.search?.status,
      filteredValue: getAtSession("resellers_page")?.search?.status,
      render: (status) => (
        <Tag color={status === "active" ? "green" : "volcano"}>{status}</Tag>
      ),
    },
    {
      title: t("budget"),
      dataIndex: "budget",
      key: "budget",
      align: "center",
      ellipsis: true,
    },
    {
      title: t("archived"),
      dataIndex: "archived",
      key: "archived",
      align: "center",
      ellipsis: true,
      filters: archiveOptions,
      defaultFilteredValue: getAtSession("resellers_page")?.search?.archived,
      filteredValue: getAtSession("resellers_page")?.search?.archived,
      render: (archived) => <Check value={archived} />,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 70,
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <TableButtons
          handleMenuClick={(key) => handleMenuClick(key, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_reseller_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const reseller = {
      page: data.page,
      sort: data.sort,
      search: data.search,
    };

    setInLocal("pageLimit_reseller_page", data.limit);
    setInSession("resellers_page", reseller);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    if (data.search) {
      setSearch(data.search);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const query = {
      page: currentPage,
      limit,
    };

    if (sort[1]) {
      query.sort = sort;
    }

    if (search.full_name) {
      query.search = {
        full_name: search.full_name,
      };
    }

    if (search.email) {
      query.search = {
        email: search.email,
      };
    }

    if (search.phone) {
      query.search = {
        phone: search.phone,
      };
    }

    if (search.address) {
      query.search = {
        address: search.address,
      };
    }

    if (search.country) {
      query.filter = {
        country: search.country,
      };
    }

    if (search.status) {
      query.filter = {
        status: search.status,
      };
    }

    if (search.archived) {
      query.filter = {
        archived: search.archived,
      };
    }

    const success = (data) => {
      setDataSources(data.rows);
      setLimit(data.limit);
      setTotal(data.total);
    };

    const error = (err) => {
      console.log(err);
    };

    dataSource(query, success, error);
  }, [search, sort, currentPage, limit, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {selectedRowKeys.length > 0 && (
              <ButtonComponent
                icon={icons.DELETE}
                title={t("delete")}
                type="default"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  bulkAction("delete", selectedRowKeys, (response) => {
                    if (response == getAgain) {
                      setSelectedRowKeys([]);
                    }
                  });
                }}
              />
            )}
            <ButtonComponent
              title={t("add_user")}
              icon={icons.ADD}
              onClick={() => {
                addUser(true);
              }}
            />
          </div>
        }
        dataSource={dataSources}
        columns={columns}
        isLoading={loading}
        onChange={handleTableChange}
        rowSelection={dataSource.length > 1 && rowSelection}
        pagination={{
          page: currentPage,
          limit,
          total,
        }}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};

export default ResellerTable;
