import { useState, useEffect } from "react";
import { Button, Checkbox, Divider, Drawer, Typography } from "antd";
import { getAtLocal, setInLocal } from "../../helpers/storages";
import { useTranslation } from "react-i18next";

const TableConfigPopup = ({ open, onClose, tableId = "", columns = [], getAgain }) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    try {
      if (tableId) {
        const storedConfig = JSON.parse(localStorage?.getItem(`tableConfig_${tableId}`));
        if (storedConfig) {
          setCheckedItems(storedConfig);
        } else {
          const initialCheckedItems = {};
          const setDefaultCheck = columns?.map((column) => {
            initialCheckedItems[column?.key] = true;
            setCheckedItems((prevCheckedItems) => ({
              ...prevCheckedItems,
              [column?.key]: getAtLocal(`tableConfig_${tableId}`, column?.key, true),
            }));
          });

          setCheckedItems(initialCheckedItems || {});
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [tableId, open, columns]);

  const handleSave = () => {
    localStorage.setItem(`tableConfig_${tableId}`, JSON.stringify(checkedItems));
    getAgain((prev) => !prev);
    onClose();
  };

  const handleCheckboxChange = (columnKey, isChecked) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [columnKey]: isChecked || false,
    }));
  };
  const filteredColumns = columns?.filter(
    (column) =>
      column.key !== "index" &&
      column.key !== "operation" &&
      column.key !== "id" &&
      column.key !== "actions"
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={500}
      title={t("config_popup_table")}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
          <Button onClick={onClose}>{t("cancel")}</Button>
          <Button type="primary" onClick={handleSave}>
            {t("save")}
          </Button>
        </div>
      }
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* <Typography.Title level={4}>{t("config_popup_table")}</Typography.Title> */}
        <Divider />
        {filteredColumns?.map((column, i) => (
          <div key={i}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>{column.title}</span>
              <Checkbox
                checked={checkedItems[column?.key] || false}
                onChange={(e) => handleCheckboxChange(column.key, e.target.checked)}
              />
            </div>
            <Divider
              style={{
                margin: "10px 0",
              }}
            />
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default TableConfigPopup;
