import { useContext, useEffect, useState } from "react";
import { AdsContext } from "../AdsContentPage";
import CollectionPreview from "./preview/collection/CollectionPreview";
import VolumePreview from "./preview/volume/VolumePreview";
import PlayerBannerPreview from "./preview/player-banner/PlayerBannerPreview";
import SplashScreenPreview from "./preview/splash-screen/SplashScreenPreview";
import { useTranslation } from "react-i18next";

const AdsPreview = ({ data }) => {
  const { t } = useTranslation();
  const { previewData, type } = useContext(AdsContext);

  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (type === "collection") {
      setPreview(<CollectionPreview collection={previewData} />);
    } else if (type === "volume") {
      setPreview(<VolumePreview data={previewData} />);
    } else if (type === "player_banner") {
      setPreview(<PlayerBannerPreview data={previewData} />);
    } else if (type === "splash_screen") {
      setPreview(<SplashScreenPreview data={previewData} />);
    }
  }, [type, previewData]);

  return <>{preview}</>;
};

export default AdsPreview;
