import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableComponent from "../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import icons from "../../../../config/icons";
import dayjs from "dayjs";
import TableImage from "../../../../_components/table/TableImage";
import TableButtons from "../../../../_components/table/TableButtons";
import { getAtSession, setInSession } from "../../../../helpers/storages";
import { asc_desc } from "../../../../helpers/ascDesc";
import { getColumnSearchProps } from "../../../../config/config";

const RadioCategoriesTable = ({
  getDataSource,
  getAgain,
  loading,
  handleMenuClick,
  onAdd,
  host,
}) => {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("radio_categories")?.page || 1
  );
  const [limit, setLimit] = useState(getAtSession("radio_categories")?.limit || 10);
  const [total, setTotal] = useState(5);

  const [sort, setSort] = useState(
    getAtSession("radio_categories")?.sort || ["id", "DESC"]
  );
  const [search, setSearch] = useState(
    getAtSession("radio_categories")?.search || {
      name: "",
    }
  );

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      fixed: "left",
      width: 50,
      sorter: true,
      defaultSortOrder:
        getAtSession("radio_categories")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("radio_categories")?.sort?.[1]),
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      defaultFilteredValue: getAtSession("radio_categories")?.search?.name,
      filteredValue: getAtSession("radio_categories")?.search?.name,
      ...getColumnSearchProps(),
    },
    {
      title: t("image"),
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (text, record) => {
        return <TableImage src={`${host}${record.image}`} />;
      },
    },
    {
      title: t("created_at"),
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (text) => {
        return dayjs(text).format("DD/MM/YYYY");
      },
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 70,
      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={[
              {
                key: "edit",
                name: t("edit"),
                icon: icons.EDIT,
              },
              {
                key: "delete",
                name: t("delete"),
                question: t("delete_category", { name: record.name }),
                icon: icons.DELETE,
                onConfirm: () => {
                  handleMenuClick({ key: "delete" }, record);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    const radio_categories = {
      page: data.page,
      sort: data.sort,
      search: data.search,
    };

    setInSession("radio_categories", radio_categories);
    setCurrentPage(data.current);
    setLimit(data.pageSize);
    setSort(data.sort);
    setSearch(data.search);
  };

  useEffect(() => {
    const query = {
      page: currentPage,
      limit: limit,
      total,
    };

    if (sort[1]) {
      query.sort = sort;
    }

    if (search.name) {
      query.search = {
        name: search.name,
      };
    }

    const success = (response) => {
      setDataSource(response?.rows);
      setTotal(response?.total);
      setCurrentPage(response?.page);
    };

    const error = (error) => {
      console.log(error);
    };

    getDataSource(query, success, error);
  }, [search, sort, currentPage, limit, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div>
            <ButtonComponent
              title={t("add_category")}
              icon={icons.ADD}
              onClick={() => {
                onAdd();
              }}
            />
          </div>
        }
        onChange={handleTableChange}
        columns={columns}
        dataSource={dataSource}
        isLoading={loading}
        pagination={{
          current: currentPage,
          pageSize: limit,
          total: total,
        }}
      />
    </div>
  );
};

export default RadioCategoriesTable;
