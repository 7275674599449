import { Flex, Grid } from "antd";
import ImpressionChart from "./chart/ImpressionChart";
import EarningByTypeChart from "./chart/EarningByTypeChart";
import EarningByPlatformChart from "./chart/EarningByPlatformChart";
import EarningByRegionChart from "./chart/EarningByRegionChart";
import EarningByTopCLientsChart from "./chart/EarningByTopCLientsChart";
import EarningByTopRegionChart from "./chart/EarningByTopRegionChart";
import REQUESTS from "../../../../api/requests";
import { useEffect, useState } from "react";
import icons from "../../../../config/icons";
import Total from "../vod/totals/Total";

const AdsTab = () => {
  const [data, setData] = useState([]);

  const iconsObj = {
    total_ads_retrieve: icons.LOGS,
    total_campaigns: icons.CAMPAIGN,
    total_clicks: icons.CLICK,
    total_clients: icons.USERS,
    total_earnings: icons.CUSTOM_ADS,
    total_impressions: icons.STATISTICS,
    total_running_ads: icons.TOTAL,
  };

  const getTotalCount = () => {
    try {
      REQUESTS.DASHBOARD.ADS.GET_TOTAL_COUNT()
        .then((res) => {
          console.log(res);

          const totalData = Object.keys(res).map((key) => {
            return {
              title: key?.split("_").join(" "),
              count: res[key],
              icons: iconsObj[key],
            };
          });

          const filterData = totalData.filter(
            (item) =>
              item.title !== "createdAt" &&
              item.title !== "updatedAt" &&
              item.title !== "id"
          );

          console.log(filterData);

          setData(filterData);

          // Object
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTotalCount();
  }, []);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr)) ",
          gap: "20px",
        }}
      >
        {data.map((item, index) => {
          return (
            <div>
              <Total
                key={index}
                title={item.title}
                count={item.count}
                icon={item.icons}
              />
            </div>
          );
        })}
      </div>

      <Flex wrap="wrap" gap="20px">
        <ImpressionChart />
        <EarningByTypeChart />
        <EarningByRegionChart />

        <EarningByPlatformChart />
        <EarningByTopCLientsChart />
        <EarningByTopRegionChart />
        {/* <EarningByTopCampaignChart /> */}
      </Flex>
    </>
  );
};

export default AdsTab;
