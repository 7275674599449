import styles from "./styles.module.scss";
import bannerBg from "../image/player_banner.jpg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const PlayerBannerPreview = ({ data = {} }) => {
  const { t } = useTranslation();

  const {
    title = "Default Title",
    description = "Default Description",
    previewLogo,
    previewBg,
    phone,
    address,
    email,
  } = data;

  // useEffect(() => {
  //   const element = document.getElementById("player-banner");
  //   if (element) {
  //     element.addEventListener("click", () => {
  //       element.requestFullscreen();
  //     });
  //   }
  // }, []);

  return (
    <div
      className={styles["player-banner"]}
      style={{ backgroundImage: `url(${bannerBg})` }}
    >
      <div
        className={styles["player-banner-wrapper"]}
        style={{ backgroundImage: `url(${previewBg || ""})` }}
      >
        <div className={styles["player-banner-logo"]}>
          {previewLogo && <img src={previewLogo} alt="logo" />}
          <div className={styles["player-banner-content"]}>
            <div className={styles["player-banner-title"]}>
              <span>{title}</span>
            </div>
            <div className={styles["player-banner-description"]}>
              <span>{description}</span>
            </div>
          </div>
        </div>
        {address &&
          email &&
          (phone ? (
            <div className={styles["player-banner-contact"]}>
              <span>{`${t("address")}: ${address}`}</span>
              <span>{`${t("phone")}: ${phone}`}</span>
              <span>{`${t("email")}: ${email}`}</span>
            </div>
          ) : null)}
      </div>
    </div>
  );
};

export default PlayerBannerPreview;
