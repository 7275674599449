import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import store from "./redux-store/store";
import "bootstrap/dist/css/bootstrap.css";

import App from "./App";
import "./i18n";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const handleValue = (value) => {
  if (value) {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        navigator?.serviceWorker
          .register("/serviceWorker.js")

          .then((registration) => {
            console.log(registration);
            console.log("Service Worker registered with scope:", registration?.scope);
          })
          .catch((error) => {
            console.error("Service Worker registration failed:", error);
          });
      });
    }
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LeuMmMpAAAAAHifARoCEz8uYSSxHsyn4Sz6lQ7P">
    <Provider store={store}>
      <HashRouter>
        {/* <ConfigProvider direction="rtl"> */}
        <App toggleWorker={(value) => handleValue(value)} />
        {/* </ConfigProvider> */}
      </HashRouter>
    </Provider>
  </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
