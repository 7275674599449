import { Modal, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const AuthModal = ({ show, handleClose, save }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleFinish = (value) => {
    save(value);
  };

  return (
    <Modal
      title={t("authentication")}
      open={show}
      onCancel={handleClose}
      onOk={() => form.submit()}
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          name="username"
          label={t("username")}
          rules={[{ required: true, message: t("username_required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label={t("password")}
          rules={[{ required: true, message: t("password_required") }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AuthModal;
