import { useEffect, useState } from "react";
import { Form, Input, Drawer, Button, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../../../components/ImageUpload";
import REQUESTS from "../../../../../api/requests";
import { useGameCategoriesOptions } from "../../../../../hooks/selectOptions";
import dayjs from "dayjs";

const GameDrawer = ({ open, onClose, appHost, editData, onSave }) => {
  const ageRestrictionOptions = [
    { label: "0+", value: "0" },
    { label: "7+", value: "7" },
    { label: "12+", value: "12" },
    { label: "16+", value: "16" },
    { label: "18+", value: "18" },
  ];

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const gameCategoriesOption = useGameCategoriesOptions();

  const [image, setImage] = useState({
    url: null,
    file: null,
    status: "uploaded",
  });

  const finish = (values) => {
    const imageUrl = image.url.split(appHost).join("");

    const body = {
      ...values,
      name: values.name,
      category_id: values.category_id.toString(),
      year: values.year.format("YYYY"),
    };
    const editImage = editData?.image.split(appHost).join("");
    if (editImage == imageUrl) {
      delete body.image;
    } else {
      body.gallery = imageUrl;
      body.image = imageUrl;
    }

    onSave(body);
  };

  const onUpload = (value) => {
    try {
      if (value.file === null) return;

      const formData = new FormData();

      formData.append("file", value.file);

      REQUESTS.ENTERTAINMENTS.FILE(formData)
        .then((response) => {
          setImage({
            url: `${appHost}${response.path}`,
            status: "uploaded",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (image.file) {
      onUpload(image);
    }
  }, [image]);

  useEffect(() => {
    if (editData) {
      setImage({
        url: editData?.image,
        status: "uploaded",
      });

      const musicCategoriesId = gameCategoriesOption?.find(
        (item) =>
          item.value == editData?.games_categories.map((item) => item?.category_id)
      );

      const editableData = {
        ...editData,
        category_id: musicCategoriesId?.value,
        year: dayjs().year(editData?.year),
        age_restricted: editData?.age_restricted.toString() + "+",
      };

      form.setFieldsValue(editableData);
    } else {
      setImage({
        url: null,
        file: null,
        status: "uploaded",
      });
      form.resetFields();
    }
  }, [editData]);

  return (
    <Drawer
      open={open}
      title={editData ? t("edit_game") : t("add_game")}
      onClose={onClose}
      width={600}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {t("cancel")}
          </Button>
          <Button onClick={() => form.submit()} type="primary">
            {t("save")}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={finish}>
        <Form.Item label={t("image")} name="image">
          <ImageUpload image={image} setImage={setImage} />
        </Form.Item>
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("name_message") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("description")}
          name="description"
          rules={[{ required: true, message: t("description_message") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="age_restricted" label={t("age_restriction")}>
          <Select options={ageRestrictionOptions} />
        </Form.Item>
        <Form.Item
          label={t("url")}
          name="url"
          rules={[
            {
              type: "url",
              message: t("invalid_url"),
            },
            { required: true, message: t("url_message") },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t("year")} name="year">
          <DatePicker
            picker="year"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>

        <Form.Item
          label={t("category_id")}
          name="category_id"
          rules={[{ required: true, message: t("category_id") }]}
        >
          <Select options={gameCategoriesOption} />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default GameDrawer;
