import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { io } from "socket.io-client";
import SshDrawer from "./drawer/SshDrawer";
import AuthModal from "./modal/AuthModal";

const SOCKET_URL = "https://sshconnect.inorain.com";

const SshTerminal = () => {
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [terminalOpen, setTerminalOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("idle");

  const initializeSocket = (username, password) => {
    const newSocket = io(SOCKET_URL, {
      auth: { username, password },
      transports: ["polling"],
    });

    setConnectionStatus("connecting");

    newSocket.on("connect", () => {
      console.log("Socket connected");
      setSocket(newSocket);
      setConnectionStatus("connected");
    });

    newSocket.on("connect_error", (err) => {
      console.error("Connection error:", err.message);
      setConnectionStatus("error");
      newSocket.disconnect();
      setSocket(null);
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
      setConnectionStatus("idle");
    });

    return newSocket;
  };

  const handleAuthSave = (credentials) => {
    const { username, password } = credentials;
    if (!username || !password) return;

    initializeSocket(username, password);
    setAuthModalOpen(false);
    setTerminalOpen(true);
  };

  useEffect(() => {
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket, connectionStatus]);

  return (
    <div className={styles["ssh"]}>
      <div
        className={styles["ssh-terminal"]}
        onClick={() => {
          if (!socket) {
            setAuthModalOpen(true);
          } else {
            setTerminalOpen(true);
          }
        }}
      >
        <i className="fas fa-terminal" />
      </div>
      <div className={styles["ssh-terminal-drawer"]}>
        {terminalOpen && (
          <SshDrawer
            open={terminalOpen}
            socket={socket}
            onClose={() => setTerminalOpen(false)}
          />
        )}
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
        }}
      >
        <AuthModal
          show={authModalOpen}
          handleClose={() => setAuthModalOpen(false)}
          save={handleAuthSave}
        />
      </div>
    </div>
  );
};

export default SshTerminal;
