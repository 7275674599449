import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../../_components/page/PageComponent";
import MusicTable from "./MusicTable";
import REQUESTS from "../../../../api/requests";
import MusicDrawer from "./drawer/MusicDrawer";
import { appHost } from "../../../../helpers/appHost";
import { notification } from "antd";

const MusicPage = () => {
  const { t } = useTranslation();

  const host = appHost;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [editData, setEditData] = useState(null);
  const [getAgain, setGetAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const getDataSource = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.ENTERTAINMENTS.MUSIC.MUSIC_GETTER({ query: JSON.stringify(query) })
        .then((response) => {
          setLoading(false);
          success(response);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = (data, type) => {
    setLoading(true);
    try {
      let id = [];
      if (type == "many") {
        id = data.join(",");
      } else {
        id = data?.toString();
      }

      REQUESTS.ENTERTAINMENTS.MUSIC.DELETE_MUSIC(id)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("delete_message", { name: t("music") }),
          });
          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = ({ key }, record) => {
    console.log(key, record);

    switch (key) {
      case "delete":
        onDelete(record.id, "one");
        break;
      case "edit":
        setEditData(record);
        setOpenDrawer(true);
        break;
      default:
        break;
    }
  };

  const handleSave = (data) => {
    if (editData) {
      onEdit(data);
    } else {
      onAdd(data);
    }
  };

  const onAdd = (value) => {
    setLoading(true);
    try {
      REQUESTS.ENTERTAINMENTS.MUSIC.ADD_MUSIC(value)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("saved_successfully"),
          });
          setOpenDrawer(false);
          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onEdit = (value) => {
    try {
      REQUESTS.ENTERTAINMENTS.MUSIC.EDIT_MUSIC(editData.id, value)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("saved_successfully"),
          });
          setOpenDrawer(false);
          setGetAgain((prev) => !prev);
          setEditData(null);
        })
        .catch((error) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageComponent routes={[t("music"), t("music")]}>
      <MusicTable
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        setOpenDrawer={setOpenDrawer}
        getAgain={getAgain}
        loading={loading}
      />
      <MusicDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          setEditData(null);
        }}
        editData={editData}
        categoriesOptions={categoriesOptions}
        appHost={host}
        onSave={handleSave}
        loading={loading}
      />
    </PageComponent>
  );
};

export default MusicPage;
