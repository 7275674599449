import { useContext, useState } from "react";
import styles from "../style.module.scss";
import { useTranslation } from "react-i18next";
import Upploader from "../../../../_components/uploader/Uploader";
import REQUESTS from "../../../../api/requests";
import { AdsContext } from "../AdsContentPage";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import icons from "../../../../config/icons";

const AdsUpload = () => {
  const { setPreviewData, setGetAgain, type } = useContext(AdsContext);

  const { t } = useTranslation();

  const [isUpdateLogo, setIsUpdateLogo] = useState(false);
  const [isUpdateBg, setIsUpdateBg] = useState(false);

  const handleUploadLogo = (info) => {
    const formData = new FormData();

    formData.append("file", info.file.originFileObj);

    if (info?.file?.originFileObj) {
      setIsUpdateLogo(true);
    }

    upload(formData, "logo_image");
  };

  const handleUploadBackGround = (info) => {
    const formData = new FormData();

    formData.append("file", info.file.originFileObj);

    upload(formData, "bg_image");
    setIsUpdateBg(true);
  };

  const upload = (formData, uploadFrom) => {
    try {
      REQUESTS.CUSTOM_ADS.UPLOAD.UPLOAD(formData)
        .then((res) => {
          if (uploadFrom === "logo_image") {
            setPreviewData((prev) => ({
              ...prev,
              previewLogo: res.data.path,
            }));
          } else if (uploadFrom === "bg_image") {
            setPreviewData((prev) => ({
              ...prev,
              previewBg: res.data.path,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveLogo = () => {
    setPreviewData((prev) => ({
      ...prev,
      previewLogo: "",
    }));
    setGetAgain((prev) => !prev);
    setIsUpdateLogo(false);
  };

  const handleRemoveBg = () => {
    setPreviewData((prev) => ({
      ...prev,
      previewBg: "",
    }));
    setGetAgain((prev) => !prev);
    setIsUpdateBg(false);
  };

  return (
    <div className={styles["ads-upload"]}>
      {!isUpdateLogo ? (
        <Upploader
          type="logo"
          onChange={handleUploadLogo}
          title={t("uploads_logo")}
          accept="image/*"
          remove={handleRemoveLogo}
        />
      ) : (
        <div>
          <ButtonComponent
            title={t("replace_logo")}
            icon={icons.RESET}
            onClick={handleRemoveLogo}
            danger
          />
        </div>
      )}
      {type !== "volume" && (
        <>
          {!isUpdateBg ? (
            <Upploader
              type="background"
              accept="image/*"
              onChange={handleUploadBackGround}
              title={t("uploads_background")}
              remove={handleRemoveBg}
            />
          ) : (
            <div>
              <ButtonComponent
                title={t("replace_background")}
                icon={icons.RESET}
                onClick={handleRemoveBg}
                danger
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdsUpload;
